import React from 'react';
import './CasinoHotels.css';

// Örnek casino otelleri listesi
const hotels = [
  {
    name: "The Star Sydney Casino Hotel",
    address: "80 Pyrmont St, Pyrmont NSW 2009, Australia",
    phone: "+61 2 9777 9000",
    description: "One of Australia’s premier casino hotels offering luxury accommodations, fine dining, and a wide variety of gaming options.",
    website: "https://www.star.com.au/sydney/"
  },
  {
    name: "Crown Melbourne Casino Hotel",
    address: "8 Whiteman St, Southbank VIC 3006, Australia",
    phone: "+61 3 9292 8888",
    description: "A luxury casino hotel located along the Yarra River offering premium gaming and entertainment experiences.",
    website: "https://www.crownmelbourne.com.au/"
  },
  {
    name: "The Star Gold Coast Casino Hotel",
    address: "1 Casino Dr, Broadbeach QLD 4218, Australia",
    phone: "+61 7 5592 8100",
    description: "Combines beachside relaxation with the thrill of casino gaming, featuring a wide range of slot machines, table games, and poker rooms.",
    website: "https://www.star.com.au/goldcoast/"
  }
  // Diğer otelleri de buraya ekleyin.
];

const CasinoHotels = () => (
  <div className="casino-hotels">
    <h2>Casino Hotels</h2>
    <p>Explore the top casino hotels in Australia, offering luxurious accommodations, fine dining, and world-class gaming experiences.</p>
    <div className="hotel-list">
      {hotels.map((hotel, index) => (
        <div className="hotel-card" key={index}>
          <h3>{hotel.name}</h3>
          <p><strong>Address:</strong> {hotel.address}</p>
          <p><strong>Phone:</strong> {hotel.phone}</p>
          <p>{hotel.description}</p>
          <a href={hotel.website} target="_blank" rel="noopener noreferrer">Visit Website</a>
        </div>
      ))}
    </div>
  </div>
);

export default CasinoHotels;
