import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import IntroductionSection from './components/IntroductionSection';
import AboutSection from './components/AboutSection';
import TopCasinoHotelsSection from './components/TopCasinoHotelsSection';
import ContactUsSection from './components/ContactUsSection';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import CookiePolicy from './pages/CookiePolicy';
import CasinoHotels from './pages/CasinoHotels';
import CasinoHotels2 from './pages/CasinoHotels2'; // Yeni sayfa bileşeni

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <div className="container">
                <IntroductionSection />
                <AboutSection />
                <TopCasinoHotelsSection />
                <ContactUsSection />
              </div>
            }
          />
          <Route path="/casino-hotels" element={<CasinoHotels />} />
          <Route path="/casino-hotels-2" element={<CasinoHotels2 />} /> {/* Yeni rota */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
