import React from 'react';

const hotels = [
    {
      name: "The Star Sydney",
      address: "80 Pyrmont St, Pyrmont NSW 2009, Australia",
      phone: "+61 2 9777 9000",
      description: "The Star Sydney is one of Australia’s premier casino hotels, offering luxury accommodations, fine dining, and a wide variety of gaming options. With stunning views of Darling Harbour, this hotel is a popular destination for both locals and international visitors seeking entertainment and relaxation.",
      website: "https://www.star.com.au/sydney/"
    },
    {
      name: "Crown Melbourne",
      address: "8 Whiteman St, Southbank VIC 3006, Australia",
      phone: "+61 3 9292 8888",
      description: "Crown Melbourne is a luxury casino hotel located along the Yarra River. With a vast gaming floor, multiple high-end restaurants, and opulent rooms, this hotel offers a premium experience for those seeking both entertainment and sophistication in Melbourne.",
      website: "https://www.crownmelbourne.com.au/"
    },
    {
      name: "The Star Gold Coast",
      address: "1 Casino Dr, Broadbeach QLD 4218, Australia",
      phone: "+61 7 5592 8100",
      description: "The Star Gold Coast combines beachside relaxation with the thrill of casino gaming. This casino resort features a wide range of slot machines, table games, and poker rooms, along with luxury rooms and suites for a comfortable stay on the Gold Coast.",
      website: "https://www.star.com.au/goldcoast/"
    },
    {
      name: "Treasury Casino & Hotel Brisbane",
      address: "130 William St, Brisbane City QLD 4000, Australia",
      phone: "+61 7 3306 8888",
      description: "Located in a historic building, Treasury Casino & Hotel Brisbane offers a unique blend of heritage charm and modern luxury. With multiple gaming rooms, fine dining options, and luxurious accommodations, it’s a top choice for visitors in Brisbane.",
      website: "https://www.treasurybrisbane.com.au/"
    },
    {
      name: "Crown Perth",
      address: "Great Eastern Hwy, Burswood WA 6100, Australia",
      phone: "+61 8 9362 7777",
      description: "Crown Perth is a renowned casino resort featuring high-end accommodations, an extensive gaming floor, award-winning restaurants, and entertainment options. Located near the Swan River, Crown Perth is a popular choice for both locals and tourists in Western Australia.",
      website: "https://www.crownperth.com.au/"
    },
    {
      name: "SkyCity Adelaide",
      address: "North Terrace, Adelaide SA 5000, Australia",
      phone: "+61 8 8212 2811",
      description: "SkyCity Adelaide is a vibrant casino and entertainment venue located in the heart of Adelaide. With a recently expanded gaming floor, a variety of dining options, and luxury accommodations, it’s a must-visit destination for gaming enthusiasts.",
      website: "https://www.skycityadelaide.com.au/"
    },
    {
      name: "Country Club Tasmania",
      address: "Country Club Ave, Prospect Vale TAS 7250, Australia",
      phone: "+61 3 6335 5777",
      description: "Country Club Tasmania offers a peaceful resort experience with a golf course, horse riding, and casino gaming. This resort is perfect for those looking to relax while enjoying a range of entertainment options, from fine dining to gaming.",
      website: "https://www.countryclubtasmania.com.au/"
    },
    {
      name: "Mindil Beach Casino Resort",
      address: "Gilruth Ave, The Gardens NT 0820, Australia",
      phone: "+61 8 8943 8888",
      description: "Located in Darwin, Mindil Beach Casino Resort offers beachfront accommodations with a tropical vibe. Guests can enjoy a variety of casino games, relax by the pool, and savor fine dining with stunning ocean views.",
      website: "https://www.mindilbeachcasinoresort.com.au/"
    },
    {
      name: "Wrest Point Hotel Casino",
      address: "410 Sandy Bay Rd, Sandy Bay TAS 7005, Australia",
      phone: "+61 3 6221 1700",
      description: "As Australia’s first legal casino, Wrest Point Hotel Casino in Hobart is an iconic destination. Located on the waterfront, this casino hotel offers gaming, dining, and entertainment with beautiful views of the Derwent River.",
      website: "https://www.wrestpoint.com.au/"
    },
    {
      name: "Aquis Great Barrier Reef Resort",
      address: "Yorkeys Knob QLD 4878, Australia",
      phone: "+61 7 4038 3000",
      description: "Aquis Great Barrier Reef Resort is a luxury casino resort proposal near Cairns, aiming to combine a tropical holiday experience with gaming. Guests can explore the Great Barrier Reef while enjoying the resort’s planned high-end amenities and casino.",
      website: "https://www.aquisresorts.com/"
    },
    {
      name: "Casino Canberra",
      address: "21 Binara St, Canberra ACT 2601, Australia",
      phone: "+61 2 6257 7074",
      description: "Casino Canberra is the only casino in Australia’s capital city. With a range of table games, poker, and dining options, this casino offers an intimate and sophisticated gaming experience in the heart of Canberra.",
      website: "https://www.casinocanberra.com.au/"
    },
    {
      name: "Lasseters Hotel Casino",
      address: "93 Barrett Dr, Alice Springs NT 0870, Australia",
      phone: "+61 8 8950 7777",
      description: "Lasseters Hotel Casino offers a unique gaming experience in Alice Springs, near the scenic MacDonnell Ranges. The hotel includes a casino, several restaurants, a pool, and accommodations ideal for travelers exploring Australia’s Outback.",
      website: "https://www.lasseters.com.au/"
    },
    {
      name: "Voyages Ayers Rock Resort",
      address: "Yulara Dr, Yulara NT 0872, Australia",
      phone: "+61 2 8296 8010",
      description: "Located near Uluru, Voyages Ayers Rock Resort offers a luxury experience in Australia’s Red Centre. While not a traditional casino, the resort hosts various events and entertainment options, providing a unique experience close to one of Australia’s most iconic landmarks.",
      website: "https://www.ayersrockresort.com.au/"
    },
    {
      name: "Crown Sydney",
      address: "1 Barangaroo Ave, Barangaroo NSW 2000, Australia",
      phone: "+61 2 8871 7188",
      description: "Crown Sydney is a luxurious addition to Sydney’s skyline, offering high-end accommodations, fine dining, and exclusive VIP gaming. With stunning views of the Sydney Harbour, Crown Sydney provides a premium experience for discerning guests.",
      website: "https://www.crownsydney.com.au/"
    },
    {
      name: "Sofitel Sydney Darling Harbour",
      address: "12 Darling Dr, Sydney NSW 2000, Australia",
      phone: "+61 2 8388 8888",
      description: "While not a casino itself, Sofitel Sydney Darling Harbour offers luxury accommodations adjacent to The Star Sydney. Guests can enjoy five-star amenities with easy access to one of Australia’s premier casino venues.",
      website: "https://www.sofitelsydneydarlingharbour.com.au/"
    },
    {
      name: "The Ville Resort-Casino",
      address: "Sir Leslie Thiess Dr, Townsville QLD 4810, Australia",
      phone: "+61 7 4722 2333",
      description: "Located in Townsville, The Ville Resort-Casino is a popular destination for gaming and relaxation. With a variety of table games, slot machines, and tropical resort amenities, The Ville offers a delightful escape in North Queensland.",
      website: "https://www.the-ville.com.au/"
    },
    {
      name: "Echo Entertainment Group (Various Locations)",
      address: "Australia-wide",
      phone: "+61 2 9777 9000",
      description: "Echo Entertainment Group operates several casino hotels across Australia, including The Star in Sydney and Gold Coast. Each venue offers unique experiences, combining luxury, gaming, and entertainment.",
      website: "https://www.echoentertainment.com.au/"
    },
    {
      name: "Crown Aspinalls",
      address: "141 Park Ln, Mayfair, London W1K 2AA, United Kingdom",
      phone: "+44 20 7499 4599",
      description: "Although not in Australia, Crown Aspinalls is an exclusive casino in London under the Crown brand. Known for its VIP services, it caters to high rollers looking for a premium gaming experience.",
      website: "https://www.crownaspinalls.com/"
    }
    // Devamında 30'dan fazla örnek için daha fazla otel ekleyebilirsiniz.
  ];
  

  

const TopCasinoHotelsSection = () => (
  <section className="top-casino-hotels" id="top-casino-hotels">
    <h2>Top Casino Hotels in Australia</h2>
    {hotels.map((hotel, index) => (
      <div key={index} className="hotel">
        <h3>{hotel.name}</h3>
        <p><strong>Address:</strong> {hotel.address}</p>
        <p><strong>Phone:</strong> {hotel.phone}</p>
        <p>{hotel.description}</p>
        <a href={hotel.website} target="_blank" rel="noopener noreferrer">
          <button>Visit Website</button>
        </a>
      </div>
    ))}
  </section>
);

export default TopCasinoHotelsSection;
