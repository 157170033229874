import React from 'react';
import './CasinoHotels.css';

const hotels = [
  {
    name: "SkyCity Adelaide",
    address: "North Terrace, Adelaide SA 5000, Australia",
    phone: "+61 8 8212 2811",
    description: "Located in Adelaide, SkyCity offers luxurious accommodations, dining options, and a world-class casino experience.",
    website: "https://www.skycityadelaide.com.au/"
  },
  {
    name: "The Reef Hotel Casino",
    address: "35-41 Wharf St, Cairns City QLD 4870, Australia",
    phone: "+61 7 4030 8888",
    description: "Experience the beauty of Cairns with a stay at The Reef Hotel Casino, featuring premium gaming, restaurants, and entertainment.",
    website: "https://www.reefcasino.com.au/"
  },
  {
    name: "Country Club Tasmania",
    address: "Country Club Ave, Prospect Vale TAS 7250, Australia",
    phone: "+61 3 6335 5777",
    description: "Enjoy a blend of luxury and relaxation at Country Club Tasmania, with a golf course, spa, and extensive gaming options.",
    website: "https://www.countryclubtasmania.com.au/"
  },
  {
    name: "Treasury Brisbane",
    address: "130 William St, Brisbane City QLD 4000, Australia",
    phone: "+61 7 3306 8888",
    description: "Treasury Brisbane offers a grand casino experience in the heart of Brisbane with fine dining and premium accommodations.",
    website: "https://www.treasurybrisbane.com.au/"
  },
  {
    name: "Mindil Beach Casino Resort",
    address: "Gilruth Ave, The Gardens NT 0820, Australia",
    phone: "+61 8 8943 8888",
    description: "Nestled on the beach, Mindil Beach Casino Resort combines relaxation with a vibrant casino experience in Darwin.",
    website: "https://www.mindilbeachcasinoresort.com.au/"
  }
  // Diğer otelleri buraya ekleyebilirsiniz.
];

const CasinoHotels2 = () => (
  <div className="casino-hotels">
    <h2>Casino Hotels 2</h2>
    <p>Discover a selection of additional top casino hotels in Australia, offering unique experiences and high-end accommodations.</p>
    <div className="hotel-list">
      {hotels.map((hotel, index) => (
        <div className="hotel-card" key={index}>
          <h3>{hotel.name}</h3>
          <p><strong>Address:</strong> {hotel.address}</p>
          <p><strong>Phone:</strong> {hotel.phone}</p>
          <p>{hotel.description}</p>
          <a href={hotel.website} target="_blank" rel="noopener noreferrer">Visit Website</a>
        </div>
      ))}
    </div>
  </div>
);

export default CasinoHotels2;
